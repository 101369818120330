.CalendarDay__selected_span {
    background: #E2EDF5;
    border: 1px solid white;
    color: #333333;
  }
  .CalendarDay__selected_span:hover {
    background: #AFD3E8;
    border: 1px solid white;
    color: #333333;
  }
  .CalendarDay__selected, .CalendarDay__selected_start {
    background: #AFD3E8;
    border: 1px double #86BDDE;
  }
  .CalendarDay__selected:hover {
    background: #AFD3E8;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #E2EDF5;
    border: 1px solid white; 
  }

.DateRangePicker_picker {
    padding-right: 32px;
    background-color: transparent;
}
.DateRangePickerInput {
    border-color: #C3C3C7;
    border-radius: 8px;
}
.DateInput {
    border-radius: 8px;
    width: 110px;
}
.DateInput_input {
    border-radius: 8px;
    height: 38px;
    font-size: 1rem;
    line-height: 20px;
    font-weight: normal;
    border-bottom: 0px;
    --tw-ring-color: transparent !important;
}
.DateRangePickerInput_arrow_svg {
    height: 18px;
    width: 18px;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #1B91C7;
}