@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #F2F5FD;
  height: 100vh;
}

a {
  color: theme('colors.blue.500');
}

.cell-bad {
  background-color: #FFFBD9;
}
.cell-worst {
  background-color: #f7e2e2;
}
